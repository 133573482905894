body {
  margin: 0;
  padding-top: 60px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Open Sans", sans-serif;
    /*"PT Serif", serif;*/
}

h1 {
    font-size: 1.2em;
    font-weight: bold;
}

h2 {
    font-size: 1.1em;
    font-weight: bold;
}

h3 {
    font-size: 1em;
    font-weight: bold;
}

h4 {
    font-size: .95em;
    font-weight: bold;
}

h5 {
    font-size: .83em;
    font-weight: bold;
}

h6 {
    font-size: .67em;
    font-weight: bold;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}

input[type=file] {
    width: 100%;
}
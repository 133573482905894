.sun-editor-editable .__se__float-none {
   float: right;
}

.sun-editor-editable p {
   font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.sun-editor p {
   font-family:Verdana, Geneva, Tahoma, sans-serif;   
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body, html {
    font-family: 'Vazir', sans-serif !important;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.6rem;
}

.page-header {
  font-size: 3.2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {
  /* was 480 and below same */
  .ldAppIcon {
      width: 18%;
      height: 170;
      /* height: calc(vw * 18% * 2); */
  }
  .ldNavItem {
      color: rgb(11, 11, 27);
      display: inline-block;
      padding: 16px;
  }
  .ldLinkContainerR.nav-item {
      background-color: transparent;
  }

  .CalcPanel {
    margin:auto;
    font-size: large;
    max-width: 95%;
    background-color:rgb(232, 236, 253)
  }
  
}

@media (min-width: 768px) {
  .ldAppIcon {
      width: 50px;
      height: 47px;
  }
  .ldNavItem {
      color: rgb(11, 11, 27);
      padding: 12px;
      display: inline-block;
  }
  .ldLinkContainerR.nav-item {
      background-color: transparent;
      /*    height: 20px;*/
  }

  .ldNavDropdownAccount .dropdown-menu {
    display: flexbox;
    flex-direction: column;
    line-height: 2;
    background-color: darkgray;
  }

    .CalcPanel {
      margin:auto;
      font-size: x-large;
      max-width: 60%;
      background-color:rgb(234, 237, 247)
    
  }
  
  .ldNavDropdownBackground .dropdown-menu {
    display:flexbox;
    flex-direction: row;
    line-height: 2;
    background-color: rgb(255, 255, 255);
  }
  
}


@media (max-width: 768px) {
  .xbody {
      padding-top: 0px;
  }
  .ldNavDropdownAccount .dropdown-menu {
    display: flexbox;
    flex-direction: row;
    line-height: 2;
    background-color: darkgray;
  }
  
  .ldNavDropdownBackground .dropdown-menu {
    display:flexbox; /* wanted mobile to show across row, but flex + row = menu displayed never goes away.  grrr*/
    flex-direction: row;
    line-height: 1;
    background-color: rgb(255, 255, 255);
  }
  
}


.ShowTemp {
  margin: 20px;
  line-height: 160%;
}

.About {
  margin: 20px;
  line-height: 160%;
}

.App-header {
  /*  #282c34; */
  background-color: rgb(52, 168, 81);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(206, 70, 199);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Etc {
  margin: 10px;
  line-height: 160%;
}

.FormCheckbox {
  margin: .8em .5em;
}

.FormNumber {
   text-align: right;
   
}

.FormHighlight {
  background-color: rgb(240, 255, 101);
}

.FormAmount::before{
  content: "\0024";
}
.FormAmount {
  text-align: right;
  
}

.FormText {
  margin: 0px;
  padding: 4px;
}

.FormTextRight {
  margin: 0px;
  padding: 6px;
  text-align:right !important;
  
}

.FormInputGroup {
  padding: 0px;
  background-color: blue;
}

.FormInputPrepend {
  padding: 2px;
  
}
.Forum {
  margin: 1px;
  line-height: 100%;
}


.ForumPostCardFooter {
  border: none;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size:60%;
  background-color: white;
  text-align: left;
}

.ForumPostCardFooterRight {
  text-align: right;
}


.ForumPosts {
  height: "20%";
  /*overflow-y: scroll; */
  font-size:75%;
  
}

.ForumMenu {
  padding:0;
  margin:0;
}

.GetInvolved {
    margin: 10px;
    line-height: 160%;
}

.HomeScore {
   display: flex;
}

.HomeScore1 {
   background:"primary";
  height: 100;
  width: 100;
}

.HomeScore2 {
 height: 100;
 width: 100;
}

.How {
  margin: 10px;
  line-height: 160%;
}

.ldCancelButton {
  margin: 1px;
  padding: 1px;
  padding-left: 2px;
  padding-right: 2px;
  color: red;
  size: 70%;
}


.ldBackButton {
  /*    align-self: flex-start; */
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: 2;
  padding: 1rem;
  padding-left: .25rem;
  padding-right: .25rem;
  align-self: center;
}

.ldTab {
  background-color: transparent;
  margin: 0px;
}
.ldTabContainer {
  margin: 4px;
  padding: 10px;
  width: 99%;
}

.ldShowTempContainer {
  margin: auto;
  
  padding: 2px;
  width: 99%;
}

.ldNewPostButton {
  justify-content: end;
  margin: 0px;
  border: 2;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  align-self: center;
  font-weight: 900;
}

.newPostButtonCol {
  padding-right:auto;
}

.ldRegistryContainer {
  margin: auto;
  
  padding: 2px;
  width: 99%;
}

.ldSaveButton:disabled {
  font-weight: 200;
  /*transparent when design done */
}



.ldSaveButton {
  justify-content: end;
  margin: 0px;
  border: 2;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  align-self: center;
  font-weight: 900;
}

.ldNewButton:disabled {
  font-weight: 200;
  /*transparent when design done */
}

.ldNewButton {
  justify-content: end;
  margin: 0px;
  border: 2;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  align-self: center;
  font-weight: 900;
}

.ldButton {
  justify-content: end;
  margin: .25rem;
  border: .1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  align-self: center;
  font-weight: 900;
  font-size: 1.25rem;
}

.ldSmallButton {
  margin: .25rem;
  border: .1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  align-self: center;
  font-weight: 900;
  font-size: 1rem;
}

.ldSmallDrop {
  margin: .25rem;
  border: .1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  align-self: top;
  font-weight: 900;
  font-size: 1rem;
}

.ldFormMessage {
  background-color: transparent;
  padding: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  font-size: 90%;
  color: orangered;
}

.ldJoin {
  color: white;
  font-weight: 900;
}
.ldLinkContainerR {
  margin-right: 10px;
  font-size: 95%;
  color: black;
}

.ldlogin {
  font-weight: 950;
  color: rgb(10, 6, 65);
  padding: 8px;
}

.ldnavBackground {
  padding-left: 5px;
  padding-right: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  position: right;
  line-height: 2;
}

.ldnavBarCollapse {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: transparent;
  margin: 2px;
  line-height: 5;
}



.ldnavEtc {
  padding-left: 5px;
  padding-right: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  position: right;
  line-height: 2;
}

.Model {
  margin: 10px;
  line-height: 100%;
}

.News {
  margin: 10px;
  line-height: 160%;
}

.NewsCard {
  border-color: rgb(51, 255, 0);
  margin: 1px;
  padding: 1px !important;
}

.NewsCardHeader {
  font-weight: 900;
  /*border: none;*/
}

.NewsCardTitle {
  line-height: 100%;
  font-size: 120%;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}

.NewsCardBody {
  padding: 1px !important;
}

.NewsCardFooter {
  font-size: 75%;
  font-weight: 90%;
}

.PageFooter {
  font-size: 75%;
  font-weight: 120%;
  display: flex;
  justify-content: space-around;
}
.Pic {
   width: "40%";
   float: 'left';
}

.PicTextCardHeader {
   font-weight: 200%;
}

.PicTextCardBody {
   padding-bottom: 0;
}
.PicTextCardFooter {
  border: none;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;

  background-color: white;
  text-align: left;
}

.xPostNewEditor {
   padding:"5%";
}

.xPostNewRow {
  padding:"30";
}

.RegistryCard {
  border-color: rgb(0, 255, 136);
  margin: 1px;
  padding: 1px !important;
}

.RegistryCardFooter{
    border: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size:60%;
    background-color: white;
    text-align: left;
}

.rich-editor .rdw-editor-main {
  background-color: rgb(246, 245, 252);
 }

 .Roadmap {
  margin: 20px;
  line-height: 100%;
}


 .ldInputGroup {
   flex-wrap: nowrap;
 }

 .selectRowState {
  padding:0;
  z-index: 999 !important;
  font-size: 1.25rem;
  width: 10rem;
 }

.selectCategory {
  padding:0;
  z-index: 999 !important;
  font-size: 1.25rem;
  width: 30rem;
}

.selectCategorySm {
  padding:0;
  z-index: 999 !important;
  font-size: 1.25rem;
  width: 20rem;
}

.selectTopic {
  padding:0;
  z-index: 998 !important;
  font-size: 1.25rem;
  width: 30rem;
}

.selectPerPage {
  padding:1rem;
  margin: 0;
  border: 0;
  font-size: .75rem;
  align-self: center;
}

.DataLog {
  margin: 2px;
  line-height: 100%;
  text-align: center;
}

.Registry {
  text-align: center;
    margin: 20px;
    line-height: 100%;
}


.What {
  margin: 10px;
  line-height: 160%;
}

.Where {
  margin: 10px;
  line-height: 160%;
  text-align: center;
}

.Who {
  margin: 10px;
  line-height: 160%;
}

.Why {
  margin: 10px;
  line-height: 160%;
}

.Checkout {
   margin: 2px;
   background-color:rgb(170, 206, 252);
   padding: 1px;
}

.CardElement {
   background-color: white;
}

.CardErrors {
  background-color: rgb(251, 255, 31);
  padding: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  font-size: 90%;
  color: orangered;
  font-weight: 800;
}

.CardInput {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  position: relative;
  background-color: white;
  color:black;
  transition: 0.3s all;
}
.Field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}


.ButtonColumn {
  background-color:rgb(255, 255, 255);
  color:rgb(11, 11, 27);
  font-size:x-small;
  padding:1px;
  
}

.EditorContent {
  margin-top: 1rem;
  background-color:rgb(255, 255, 255);
  padding: 1rem;
  border: 1px solid #ccc;
  text-align: left;

}

.EditorContentPreview {
  margin-top: 1rem;
  background-color:rgb(255, 255, 255);
  padding: 1rem;
  border: 1px solid #ccc;
  text-align: left;
}


.Editorwrapper-class {
  padding: 1rem;
  border: 0px solid #ccc;
}
.Editoreditor-class {
  background-color:rgb(255, 255, 255);
  padding: 1rem;
  border: 1px solid #ccc;
}
.Editortoolbar-class {
  border: 1px solid #ccc;
}

.ActionButton {
  margin: .1rem;
  font-size: 1.25rem;
}

.ldHeaderRow {
  text-align: center;
  display: block;
}

.ldTopRow {
  margin: 0px;
  border: 0px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /*height: 50px;*/
}

.ldMidRow {
  text-align: center;
  display: inline-flex;
}

.ldRow {
  text-align: center;
  display: block;
}

.ldBodyRow {
  margin: 0px;
  border: 0px;
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
}

.ldBodyRowN {
  margin: 0px;
  border: 0px;
}

.ldBodyColLeft {
  padding-left:0;
  padding-right:0;
  padding-top:10;

}

.ldBodyColRight {
  padding-left:0rem;
  padding-right:0;
  
}




.ldCardBody {
  padding: 1px !important;
}

.ldmodifieddatetime {
  font-size: 60%;
  font-weight: 100;
  margin-left: 4px;
}

.ldCheckbox {
  margin-left: 10px;
  margin-top: 0px;
   margin-right: 0px;
   margin-bottom: 0px;
}

.ldCommentAuthor {
  font-style: italic;
  font-weight: 600;
  font-size: 140%;
  padding: 5px;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
}

.ldCommentList {
  margin: 0px;
  padding: 3px;
  background-color: rgb(242, 245, 255);
  margin-top: 15px;
}

.ldCommentListGroupItem {
  background-color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.ldCommentItem {
  display: flex;
  justify-content: space-between;

}

.ldCommentListHeader {
  padding: 3px;
  margin: 3px;
  background-color: transparent;
  font-weight: 800;
}

.ldCommentNewArea {
  background-color: rgb(156, 172, 216);
  width: 100%;
  margin: 0px;
  padding: 15px;
}

.ldCommentPage {
  background-color: transparent;
}

.ldComment {
  flex-direction:column;
  text-align:left;
  margin-left: 0;
  margin-right: 0;
}

.ldDeleteButton {
  margin: 3px;
  margin-right: 15px;
  padding: 2px;
  color: red;
  background-color: rgb(233, 181, 181);
}

.ldDeleteButtonR {
  margin: 1px;
  padding: 1px;
  padding-left: 2px;
  padding-right: 2px;
  color: red;
  size: 70%;
  border: transparent;
  background-color: rgb(233, 181, 181);
}

.ldDataNav {
    display: inline-flex;
    width: 40rem;
}

.ldItemCount {
  display: inline-flex;
  padding: 1rem;
  width: 10rem;
  font-size: 70%;
  /* line-height: 10rem;*/
  border: 0;
 
}

.ldxMessageContainer {
  background-color: transparent;
  width: 100%;
  font-weight: 600;
  color: rgb(153, 48, 30);
  align-self: center;
  justify-content: end;
}

.ldMessageContainerSm {
  background-color: transparent;
  width: 55%;
  font-weight: 600;
  color: rgb(85, 207, 207);
  align-self: center;
  justify-content: end;
}

.ldFormMessage {
  background-color: transparent;
  padding: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  font-size: 90%;
  color: orangered;
}




.ScoreBoard {
  display: flex;
  justify-content: space-around;
 
 }

.ScoreTile {
 height: 120px;
 width: 120px;
 border: 0;
 border-radius: 8px;
 padding: 4px !important;
 margin: 4px !important;

}
